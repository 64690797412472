import '../src/css/application/application.scss'

import Rails from "@rails/ujs"
import 'bootstrap'
Rails.start()

$(function(){
  $('#sp-glonav_btn').click(function(){
    $('#sp-glonav_body').slideToggle(500);
  })
})
$(function(){
  $('.menu-item-has-children').click(function(){
    $(this).find('.sub-menu').slideToggle(500);
  })
})

require("@rails/activestorage").start()
require("channels")
